import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import SEO from '../components/seo';

import { Item, Menu } from '../styles/styles.js';

const Bebidas = ({ data }) => {
  return (
    <>
      <SEO
        title="Bebidas"
        keywords={[
          `Coca Cola`,
          `Fanta laranja`,
          `Soda`,
          `Dolly`,
          `Skol`,
          `Itaipava`,
          `Amstel`,
        ]}
      />
      <Menu className="menu">
        <Slide left cascade>
          <h1 className="menu-title">Bebidas</h1>
        </Slide>

        <Fade left>
          <div className="item-image-bebidas">
            <Img
              fluid={data.bebidas.childImageSharp.fluid}
              alt="Bebidas vários sabores"
            />
          </div>
        </Fade>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Copo de Suco de Laranja</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>

          <p className="item-desc"></p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Jarra de Suco de Laranja</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">23,00</h4>
            </div>
          </div>

          <p className="item-desc"></p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Suco Prats Integral Laranja 300ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>

          <p className="item-desc"></p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Suco Prats Laranja 900ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">25,00</h4>
            </div>
          </div>

          <p className="item-desc"></p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Sprite 2l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Guaraná Antarctica Pet 2l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">16,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Guaraná Antarctica 1l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">9,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coca-Cola 2l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">15,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coca-Cola 1,5l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coca-Cola 220ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">3,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coca-Cola 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coca-Cola Zero 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coca-Cola 500ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">7,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Coca-Cola com Café 220ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Sprite 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Fanta Uva 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Fanta Guaraná 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Fanta Laranja 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Fanta Laranja 500ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">7,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Fanta Laranja ou Uva 2l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">13,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Guaraná Antarctica 269ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Guaraná Antarctica 600ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Guaraná Antarctica 1L</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Kuat 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Kuat 2L</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">12,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Kapo 200ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>

          <p className="item-desc">Laranja, Morango, Uva ou Maracujá</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Schweppes Citrus 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Slide left>
          <h2 className="menu-subtitle">Água</h2>
        </Slide>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água Mineral Puríssima s/ Gás 497ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água Mineral Puríssima c/ Gás 500ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">4,50</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água Mineral Puríssima s/ Gás 1,5l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água Tônica Antarctica 350ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água Tônica Antarctica Zero 350ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água Tônica Schweppes 310ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">6,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água Aquarius Fresh Limão 510ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">H2O Limão / Limoneto 500ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Água de Coco - Kero Coco 330ml</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Slide left>
          <h2 className="menu-subtitle">Gelo</h2>
        </Slide>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Gelo Cubo Grande</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">8,00</h4>
            </div>
          </div>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Gelo Cubo Pequeno</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">5,00</h4>
            </div>
          </div>
        </Item>
      </Menu>
    </>
  );
};

export const query = graphql`
  query {
    bebidas: file(relativePath: { eq: "bebidas.png" }) {
      childImageSharp {
        fluid(maxWidth: 275, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Bebidas;
